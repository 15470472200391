import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/seo';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Contact from '../components/contact';
import { BrandHighlight, Wrapper, above, Destyle, Global } from '../components';
import HeaderBG from '../images/header_bg.jpg';
import Logo from '../images/logo.svg';

const Thanks = styled.section`
  background: url(${HeaderBG}) no-repeat top center;
  background-size: cover;
  padding: 50px 0;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Nav = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${above.small`
    grid-template-columns: 1fr 1fr;
    img {
      align-self: center;
    }
  `}
`;

const Button = styled(AnchorLink)`
  background: ${BrandHighlight};
  color: #000;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  padding: 15px 20px;
  border-radius: 50px;
  display: inline-block;
`;

const TopCTA = styled(Button)`
  margin-top: 30px;
  ${above.small`
    max-width: 276px;
    justify-self: end;
    margin: 0;
  `}
`;

const HeadWrap = styled.div`
  max-width: 740px;
  p {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 300;
    ${above.small`
      font-size: 24px;
      margin-bottom: 40px;
    `}
    a {
      text-decoration: underline;
    }
  }
`;

const Title = styled.h1`
  font-size: 32px;
  margin: 50px 0 20px;
  ${above.small`
    font-size: 64px;
    margin: 80px 0 40px;
  `}
`;

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query ThanksQuery {
        site {
          siteMetadata {
            description
          }
        }
      }
    `}
    render={data => (
      <>
        <div>
          <SEO
            title="Private Treatment"
            keywords={[
              `rehab`,
              `treatment`,
              `therapy`,
              `counselling`,
              `rehab UK`,
              `rehab Spain`
            ]}
          />
          <Destyle />
          <Global />
          <Thanks>
            <Wrapper>
              <Nav>
                <Link to="/" title="Private Treatment">
                  <img src={Logo} alt="Private Treatment Logo" />
                </Link>
                <TopCTA href="tel:08001234567" title="Call Us">
                  Call Us Free On 0800 123 4567
                </TopCTA>
              </Nav>
              <HeadWrap>
                <Title>Message Sent!</Title>
                <p>
                  Thanks for getting in touch, we'll call you back shortly.
                  Chillwave vegan tousled roof party. Distillery ugh
                  microdosing, vice vinyl la croix 3 wolf moon aesthetic
                  stumptown.{' '}
                  <Link to="/" title="Back to home page">
                    Go back to the homepage.
                  </Link>
                </p>
                <Contact />
              </HeadWrap>
            </Wrapper>
          </Thanks>
        </div>
      </>
    )}
  />
);

export default IndexPage;
